<template>
  <div class="m-error error">
    <div class="error__image">
      <img src="@theme/assets/error.svg" alt="" />
    </div>
    <SfHeading
      :level="level"
      :title="title"
      :subtitle="subtitle"
      class="error__message"
    />
  </div>
</template>

<script>
import { SfHeading } from "@lib/components";

export default {
  name: "MError",
  components: { SfHeading },
  props: {
    level: {
      type: Number,
      default: 1,
    },
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
  },
};
</script>
<style lang="scss" scoped>
@use "~@lib/styles/helpers/breakpoints" as *;

.error {
  margin: auto;
  padding: 0 1.25rem;
  &__image,
  &__message {
    margin: calc(var(--spacer-2xl) * 2) 0;
    text-align: center;
    --heading-border: none;
  }
  &__image {
    display: flex;
    justify-content: center;
  }
  &__action {
    max-width: 24rem;
    margin: var(--spacer-2xl) auto;
    @include for-desktop {
      display: flex;
    }
  }
  &__action-button {
    flex: 1;
    &--secondary {
      margin: var(--spacer-xl) 0;
      @include for-desktop {
        margin: 0;
        text-align: right;
      }
    }
  }
}
</style>
