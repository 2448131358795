<template>
  <div id="error">
    <MError :title="errorTitle" :subtitle="errorSubtitle" />
  </div>
</template>

<script>
import i18n from "@lib/i18n";
import MError from "@theme/components/molecules/error";
import { mapState } from "vuex";

export default {
  name: "Error",
  components: {
    MError,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapState(["storeNotSpecified", "storeNotFound", "storeFetchError"]),
    errorTitle() {
      if (this.storeNotSpecified || this.storeNotFound) {
        return i18n.t("We can't find the page");
      } else if (this.storeFetchError) {
        return i18n.t("Something went wrong ...");
      }
      return i18n.t("Something went wrong ...");
    },
    errorSubtitle() {
      if (this.storeNotSpecified || this.storeNotFound) {
        return i18n.t(
          "Unfortunately we can't find the page you are looking for."
        );
      } else if (this.storeFetchError) {
        return i18n.t(
          "We've noticed Internal Server Error while rendering this request."
        );
      }
      return i18n.t(
        "We've noticed Internal Server Error while rendering this request."
      );
    },
    errorMetaTitle() {
      if (this.storeNotSpecified || this.storeNotFound) {
        return i18n.t("404 Page Not Found");
      } else if (this.storeFetchError) {
        return i18n.t("Internal Server Error 500");
      }
      return i18n.t("Internal Server Error 500");
    },
  },
};
</script>
